import {TCompanies, Users} from '@/utils/coreApi'

export default {
  async tryAuthorize( context, payload ) {
    let m2 = true;
    console.log("payload", payload)
    if ( !payload )
    {
      localStorage.removeItem( 'authToken' );
      context.commit('setAuthError', '' );
      context.commit('setAuth', false );

    } else
    if ( payload.login !== '' && payload.passw !== '' ) {
      let user = new Users()
      let access_token = await user.auth(payload.login, payload.passw)
      if (access_token) {
        let userData = await user.getByTokenInt()
        console.log("userData", userData)
        if (userData) {
          localStorage.setItem('authToken', '1');
          localStorage.setItem('token', access_token);
          localStorage.setItem('Role', userData.role);
          localStorage.setItem('username', userData.username);
          context.commit('setAuthError', '');
          context.commit('setAuth', true);
          context.commit('setRole', userData.role);
          context.commit('setToken', access_token);
          context.commit('setUserName', userData.username);

          let companyObj = new TCompanies()
          let companyReq = await companyObj.get(userData.company_id)
          console.log("companyReq", companyReq)
          if (companyReq) {
            localStorage.setItem('CompanyName', companyReq.name);
            context.commit('setCompanyName', companyReq.name);
            // let services = {}
            let sa = false
            let vc = false
            let asr = false
            let tts = false
            companyReq.services.forEach(value => {
              if (value === "Речевая аналитика") {
                sa = true
              }
              if (value === "Распознавание речи") {
                asr = true
              }
              if (value === "Виртуальный оператор") {
                vc = true
              }
              if (value === "Синтез речи") {
                tts = true
              }

              // services.push({"name": value})
            })

            let services = {
              "sa": sa,
              "vc": vc,
              "asr": asr,
              "tts": tts,
            }
            console.log("services", services)

            // localStorage.setItem('services', JSON.stringify(services));
            // context.commit('setServices', services);

          } else {
            // localStorage.setItem('CompanyName', 'ООО Рога и Копыта');
            // context.commit('setCompanyName', 'ООО Рога и Копыта');
          }

          m2 = false;
        }
      }
    }

    if (m2) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('token');
      localStorage.removeItem('Role');
      localStorage.removeItem('CompanyName');
      localStorage.removeItem('username');
      localStorage.removeItem('services');
      // context.commit('setAuthError', 'Неверный логин или пароль');
      // context.commit('setAuthError', 'Неверный логин или пароль');
      // context.commit('setAuth', false);
      context.commit('setRole', '');
      context.commit('setToken', '');
      context.commit('setCompanyName', '');
      context.commit('setUserName', '');
      // context.commit('setServices', {});
    }
  },

  async CancelAuthorize( context ) {
    localStorage.removeItem('authToken');
    localStorage.removeItem('token');
    localStorage.removeItem('Role');
    localStorage.removeItem('CompanyName');
    localStorage.removeItem('username');
    localStorage.removeItem('services');
    context.commit('setAuthError', 'Ваша сессия завершена, параллельный вход в систему');
    context.commit('setAuth', false);
    context.commit('setRole', '');
    context.commit('setToken', '');
    context.commit('setCompanyName', '');
    context.commit('setUserName', '');
    // context.commit('setServices', {});
  },

  async wrongPassword( context ) {
    context.commit('setAuthError', 'Неверный логин или пароль');
  },

  askAuthorized( context ) {
    context.commit('setAuth', localStorage.getItem('authToken') !== null );
  },
  askRole( context ) {
    context.commit('setRole', localStorage.getItem('Role'));
  },
  askCompanyName( context ) {
    context.commit('setCompanyName', localStorage.getItem('CompanyName'));
  },
  askUserName( context ) {
    context.commit('setUserName', localStorage.getItem('username'));
  },
  askToken( context ) {
    context.commit('setToken', localStorage.getItem('token'));
  }
}