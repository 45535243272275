export default {
  getAuthError: state => {
    return state.authError; 
  },

  getAuth: state => { 
    return state.auth; 
  },

  getRole: state => {
    return state.role;
  },
  getCompanyName: state => {
    return state.companyName;
  },
  getUserName: state => {
    return state.username;
  },
  getToken: state => {
    return state.token;
  }

}