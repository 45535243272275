<template>
  <div class="master_font">
    <template v-if="$store.getters['users/getAuth']">
      <Sidebar
          :sidebarProp="activeContent"
          @open="openSidebar()"
          @close="closeSidebar()"
      ></Sidebar>
      <div class="main_cont" :class="{ active_cont: activeContent, active_cont_disabled: activeContentDisabled }">
        <router-view/>
      </div>
    </template>
    <template v-else>
      <Authorization @checkAuth="checkAuth"/>
    </template>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import Sidebar from './components/Sidebar.vue'
import Authorization from './components/Authorization.vue'

// import { authenticationService } from "@/services/authentication.service";
// import { Role } from "@/helpers/role";

export default {
  name: 'App',
  setup()
  {
    const store = useStore();
    store.dispatch('users/askAuthorized');
    store.dispatch('users/askRole');
    store.dispatch('sidebar/askSidebarState');
    store.dispatch('users/askCompanyName');
    store.dispatch('users/askUserName');
    store.dispatch('users/askToken');
  },
  components: {
    Sidebar,
    Authorization,
  },
  data() {
    return {
      activeContent: true,
      activeContentDisabled: false,
      currentUser: null
    }
  },
  computed: {
    // isAdmin() {
    //   return this.currentUser && this.currentUser.role === Role.Admin;
    // }
  },
  created() {
    // authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    // const store = useStore();
    // console.log("getRole store", store.getters['users/getRole'])
    console.log("getRole", this.$store.getters['users/getRole'])
    // console.log("getAuth store", store.getters['users/getAuth'])
    console.log("getAuth", this.$store.getters['users/getAuth'])
    this.activeContent = this.$store.getters['sidebar/getSidebarState']
    this.activeContentDisabled = !this.activeContent

  },
  mounted() {

  },
  methods: {
    checkAuth() {
      console.log("chech auth")
      console.log(this.$store.getters['users/getAuth'])
      this.$store.dispatch('sidebar/askSidebarState');
      this.openSidebar()
      this.$forceUpdate()
    },
    openSidebar() {
      this.activeContent = true
      this.activeContentDisabled = false
      this.$store.dispatch('sidebar/trySetSidebarState', {
        state: true,
      });
    },
    closeSidebar() {
      this.activeContent = false
      this.activeContentDisabled = true
      this.$store.dispatch('sidebar/trySetSidebarState', {
        state: false,
      });

    }
  }
}
</script>

<style>
/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*  margin-top: 60px;*/
/*}*/


.active_cont {
  margin-left: 399px;
  transition: 0.5s;
}

.active_cont_disabled {
  margin-left: 79px;
  transition: 0.5s;
}

.main_cont {
  transition: 0.5s;
  /*padding-left: 5px;*/
}

.master_font {
  font-family: 'TT Hoves', serif;
  font-weight: normal;
}

</style>
