import Config from "@/config";
import axios from "axios";
import { store } from "@/store";

export class ApiUsers {

    // async auth(login, password) {
    //     let bodyFormData = new FormData();
    //     bodyFormData.append('username', login);
    //     bodyFormData.append('password', password);
    //     const config = {
    //         headers: {"Content-Type": "multipart/form-data"}
    //     };
    //     let  url = Config.apiUrl + '/auth/';
    //     let response = await axios.post(url, bodyFormData, config)
    //         if (response.status === 200) {
    //             console.log("access_token", response.data.access_token)
    //             this.token = response.data.access_token
    //             return response.data.access_token
    //         } else {
    //             return null
    //         }
    // }

    async getByToken(token) {
        console.log("getByToken token", token)
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let urlUser = Config.apiUrl + '/users/me/';
        let responseUser = await axios.get(urlUser, config)
            if (responseUser.status === 200) {
                return responseUser.data
            } else {
                return {}
            }
    }

    async getByTokenInt() {
        // console.log("getByToken token", token)
        const config = {
            headers: { Authorization: `Bearer ${this.token}` }
        };
        let urlUser = Config.apiUrl + '/users/me/';
        let responseUser = await axios.get(urlUser, config)
        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {}
        }
    }

}


class masterApiToken {
    constructor(classname) {
        this.classname = classname;
        this.apiUrl = Config.apiUrl;
        this.token = store.getters['users/getToken'];
    }

    async get(id) {
        const config = {
            headers: { Authorization: `Bearer ${this.token}`, 'Content-type': 'application/json'
            }
        };
        let urlUser = this.apiUrl + id;
        let responseUser = await axios.get(urlUser, config)
            .catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    store.dispatch('users/CancelAuthorize');
                }
                return {}
            })
        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {}
        }
    }

    async getAll() {
        const config = {
            headers: { Authorization: `Bearer ${this.token}`, 'Content-type': 'application/json'
            }
        };
        let urlUser = this.apiUrl;
        let responseUser = await axios.get(urlUser, config)
            .catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    store.dispatch('users/CancelAuthorize');
                }
                return []
            })
        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return []
        }
    }

    async add(body) {
        const config = {
            headers: { Authorization: `Bearer ${this.token}`, 'Content-type': 'application/json'
            }
        };
        let urlUser = this.apiUrl;
        let responseUser = await axios.put(urlUser, body, config)
            .catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    store.dispatch('users/CancelAuthorize');
                }
                return {}
            })
        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {}
        }
    }

    async update(id ,body) {
        const config = {
            headers: { Authorization: `Bearer ${this.token}`, 'Content-type': 'application/json'
            }
        };
        let urlUser = this.apiUrl + id;
        let responseUser = await axios.post(urlUser, body, config)
            .catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    store.dispatch('users/CancelAuthorize');
                }
                return {}
            })
        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {}
        }
    }

    async delete(id) {
        const config = {
            headers: { Authorization: `Bearer ${this.token}`, 'Content-type': 'application/json'
            }
        };
        let urlUser = this.apiUrl + id;
        let responseUser = await axios.delete(urlUser, config)
            .catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    store.dispatch('users/CancelAuthorize');
                }
                return {}
            })
        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {}
        }
    }
}


class masterApi {
    constructor(classname) {
        this.classname = classname;
        this.apiUrl = Config.apiUrl + '/' + this.classname + '/';
    }

    async get(id) {
        const config = {
            headers: {'Content-type': 'application/json'
            }
        };
        let urlUser = this.apiUrl + id;
        let responseUser = await axios.get(urlUser, config)
            // .then(response => {return response.data})
            .catch(error => {
                console.log(error)
                return {}
            })
        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {}
        }
    }

    async getAll() {
        const config = {
            headers: {'Content-type': 'application/json'
            }
        };
        let urlUser = this.apiUrl;
        let responseUser = await axios.get(urlUser, config)
            // .then(response => {return response.data})
            .catch(error => {
            console.log(error)
            return[]
        })
        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return []
        }
    }

    async add(body) {
        const config = {
            headers: {'Content-type': 'application/json'
            }
        };
        let urlUser = this.apiUrl;
        let responseUser = await axios.put(urlUser, body, config)
            // .then(response => {return response.data})
            .catch(error => {
                console.log(error)
                return {}
            })
        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {}
        }
    }

    async update(id ,body) {
        const config = {
            headers: {'Content-type': 'application/json'
            }
        };
        let urlUser = this.apiUrl + id;
        let responseUser = await axios.post(urlUser, body, config)
            // .then(response => {return response.data})
            .catch(error => {
                console.log(error)
                return {}
            })
        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {}
        }
    }

    async delete(id) {
        const config = {
            headers: {'Content-type': 'application/json'
            }
        };
        let urlUser = this.apiUrl + id;
        let responseUser = await axios.delete(urlUser, config)
            // .then(response => {return response.data})
            .catch(error => {
                console.log(error)
                return {}
            })
        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {}
        }
    }
}


class masterApiTokenUser {
    constructor(classname) {
        this.classname = classname;
        this.apiUrl = Config.apiUrl + '/' + this.classname + '/user/';
        this.token = store.getters['users/getToken'];
    }

    async get(id) {
        const config = {
            headers: { Authorization: `Bearer ${this.token}`, 'Content-type': 'application/json'
            }
        };
        let urlUser = this.apiUrl + id;
        let responseUser = await axios.get(urlUser, config)
            .catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    store.dispatch('users/CancelAuthorize');
                }
                return {}
            })
        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {}
        }
    }

    async getAll() {
        const config = {
            headers: { Authorization: `Bearer ${this.token}`, 'Content-type': 'application/json'
            }
        };
        let urlUser = this.apiUrl;
        let responseUser = await axios.get(urlUser, config)
            .catch(error => {
                console.log(error)
                console.log(error.response.status)
                if (error.response.status === 401) {
                    store.dispatch('users/CancelAuthorize');
                }
                return []
            })
        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return []
        }
    }

    async getAllDate(dateFrom, dateTo) {
        const config = {
            headers: { Authorization: `Bearer ${this.token}`, 'Content-type': 'application/json'
            }
        };
        let dateTimezone = dateFrom.getTimezoneOffset() / -60;
        const dateData = {
            date_from: dateFrom.toJSON(),
            date_to: dateTo.toJSON(),
            timezone: dateTimezone
        }
        let urlUser = this.apiUrl + "date/";
        let responseUser = await axios.post(urlUser, dateData, config)
            .catch(error => {
                console.log(error)
                console.log(error.response.status)
                if (error.response.status === 401) {
                    store.dispatch('users/CancelAuthorize');
                }
                return []
            })
        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return []
        }
    }

    async add(body) {
        const config = {
            headers: { Authorization: `Bearer ${this.token}`, 'Content-type': 'application/json'
            }
        };
        let urlUser = this.apiUrl;
        let responseUser = await axios.put(urlUser, body, config)
            .catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    store.dispatch('users/CancelAuthorize');
                }
                return {}
            })
        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {}
        }
    }

    async update(id, body) {
        const config = {
            headers: { Authorization: `Bearer ${this.token}`, 'Content-type': 'application/json'
            }
        };
        let urlUser = this.apiUrl + id;
        let responseUser = await axios.post(urlUser, body, config)
            .catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    store.dispatch('users/CancelAuthorize');
                }
                return {}
            })
        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {}
        }
    }

    async updateCompany(id, body) {
        const config = {
            headers: { Authorization: `Bearer ${this.token}`, 'Content-type': 'application/json'
            }
        };
        let urlUser = this.apiUrl + id;
        let responseUser = await axios.post(urlUser, body, config)
            .catch(error => {
                console.log('catch error:',error)
                if (error.response?.status === 401) {
                    store.dispatch('users/CancelAuthorize');
                }
                return {}
            })

        console.log('got company data:',responseUser)

        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {}
        }
    }

    async delete(id) {
        const config = {
            headers: { Authorization: `Bearer ${this.token}`, 'Content-type': 'application/json'
            }
        };
        let urlUser = this.apiUrl + id;
        let responseUser = await axios.delete(urlUser, config)
            .catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    store.dispatch('users/CancelAuthorize');
                }
                return {}
            })
        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {}
        }
    }
}


export class Companies extends masterApi {
    constructor(classname="company") {
        super(classname);
    }
}

export class TCompanies extends masterApiTokenUser {
    constructor(classname="company") {
        super(classname);
    }
}

export class Users extends masterApi {
    constructor(classname="users") {
        super(classname);
        this.token = ''
    }

    async auth(login, password) {
        let bodyFormData = new FormData();
        bodyFormData.append('username', login);
        bodyFormData.append('password', password);
        const config = {
            headers: {"Content-Type": "multipart/form-data"}
        };
        let  url = Config.apiUrl + '/auth/';
        let response = await axios.post(url, bodyFormData, config)
            .catch(error => {
                console.log(error)
                if (error.response.status === 400) {
                    store.dispatch('users/wrongPassword');
                }
                return null
            })
        if (response.status === 200) {
            console.log("access_token", response.data.access_token)
            this.token = response.data.access_token
            return response.data.access_token
        } else {
            return null
        }
    }

    async getByTokenInt() {
        if (this.token !== '') {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` }
            };
            let urlUser = Config.apiUrl + '/users/me/';
            let responseUser = await axios.get(urlUser, config)
            // .catch(error => {
            //     console.log(error)
            //     if (error.response.status === 400) {
            //         store.dispatch('users/CancelAuthorize');
            //     }
            //     return {}
            // })
            if (responseUser.status === 200) {
                return responseUser.data
            } else {
                return {}
            }
        } else {
            return {}
        }
    }

    async setToken(token) {
        this.token = token
    }
}

export class TSAReports extends masterApiToken {
    constructor(classname = "report") {
        super(classname);
    }

    async getFilterDate(dateFrom, dateTo) {
        const config = {
            headers: {
                Authorization: `Bearer ${this.token}`, 'Content-type': 'application/json'
            }
        };
        let dateTimezone = dateFrom.getTimezoneOffset() / -60;
        const dateData = {
            date_from: dateFrom.toJSON(),
            date_to: dateTo.toJSON(),
            timezone: dateTimezone
        }
        let urlUser = this.apiUrl + '/report/sa_data/date/user/';
        let responseUser = await axios.post(urlUser, dateData, config).catch(error => {
            console.log("error", error)
            if (error.response.status === 401) {
                store.dispatch('users/CancelAuthorize');
            }
            return []
        })

        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return []
        }
    }
    async getFilterDateId(dateFrom, dateTo, report_id) {
        const config = {
            headers: {
                Authorization: `Bearer ${this.token}`, 'Content-type': 'application/json'
            }
        };
        let dateTimezone = dateFrom.getTimezoneOffset() / -60;
        const dateData = {
            date_from: dateFrom.toJSON(),
            date_to: dateTo.toJSON(),
            timezone: dateTimezone
        }
        let urlUser = this.apiUrl + '/report/sa_data/date/user/id/' + report_id;
        let responseUser = await axios.post(urlUser, dateData, config).catch(error => {
            console.log("error", error)
            if (error.response.status === 401) {
                store.dispatch('users/CancelAuthorize');
            }
            return {
                datetable_data: [],
                report_list_data: [],
                operators_arr: []
            }
        })

        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {
                datetable_data: [],
                report_list_data: [],
                operators_arr: []
            }
        }
    }
    async getFilterDateBasic(dateFrom, dateTo) {
        const config = {
            headers: {
                Authorization: `Bearer ${this.token}`, 'Content-type': 'application/json'
            }
        };
        let dateTimezone = dateFrom.getTimezoneOffset() / -60;
        const dateData = {
            date_from: dateFrom.toJSON(),
            date_to: dateTo.toJSON(),
            timezone: dateTimezone
        }
        let urlUser = this.apiUrl + '/report/sa_data/date/user/basic/';
        let responseUser = await axios.post(urlUser, dateData, config).catch(error => {
            console.log("error", error)
            if (error.response.status === 401) {
                store.dispatch('users/CancelAuthorize');
            }
            return {
                datetable_data: [],
                report_list_data: [],
                operators_arr: []
            }
        })

        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {
                datetable_data: [],
                report_list_data: [],
                operators_arr: []
            }
        }
    }
}


export class TLicences extends masterApiToken {
    constructor(classname = "licenses") {
        super(classname);
    }

    async getFilterDate(dateFrom, dateTo) {
        const config = {
            headers: {
                Authorization: `Bearer ${this.token}`, 'Content-type': 'application/json'
            }
        };
        let dateTimezone = dateFrom.getTimezoneOffset() / -60;
        const dateData = {
            date_from: dateFrom.toJSON(),
            date_to: dateTo.toJSON(),
            timezone: dateTimezone
        }
        let urlUser = this.apiUrl + '/licenses/user/';
        let responseUser = await axios.post(urlUser, dateData, config).catch(error => {
            console.log("error", error)
            if (error.response.status === 401) {
                store.dispatch('users/CancelAuthorize');
            }
            return {}
        })

        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {}
        }
    }

    async getFilterDateId(dateFrom, dateTo, report_id) {
        const config = {
            headers: {
                Authorization: `Bearer ${this.token}`, 'Content-type': 'application/json'
            }
        };
        let dateTimezone = dateFrom.getTimezoneOffset() / -60;
        const dateData = {
            date_from: dateFrom.toJSON(),
            date_to: dateTo.toJSON(),
            timezone: dateTimezone
        }
        let urlUser = this.apiUrl + '/report/sa_data/date/user/id/' + report_id;
        let responseUser = await axios.post(urlUser, dateData, config).catch(error => {
            console.log("error", error)
            if (error.response.status === 401) {
                store.dispatch('users/CancelAuthorize');
            }
            return {
                datetable_data: [],
                report_list_data: [],
                operators_arr: []
            }
        })

        if (responseUser.status === 200) {
            return responseUser.data
        } else {
            return {
                datetable_data: [],
                report_list_data: [],
                operators_arr: []
            }
        }
    }
}

