import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import { store } from '@/store';
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

let vcApp = createApp(App)
vcApp.use(router)
vcApp.use(store)
vcApp.use(VueSidebarMenu)
vcApp.use(VueAxios, axios)
vcApp.mount('#app')