export default {
  trySetSidebarState( context, payload ) {
    if ( !payload )
    {
      localStorage.removeItem( 'Sidebar' );
      context.commit('setSidebarState', false );
      
    } else

    if ( payload.state)
    {
      localStorage.setItem('Sidebar','1');
      context.commit('setSidebarState', true );

    } else {
      localStorage.removeItem( 'Sidebar' );
      context.commit('setSidebarState', false );
    }
  },

  askSidebarState( context ) {
    context.commit('setSidebarState', localStorage.getItem('Sidebar') !== null);
  }
}