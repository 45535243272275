import { createStore } from 'vuex';
import users from './modules/users/index';
import sidebar from './modules/sidebar/index';

export const store = new createStore({

    // state: {
    //     count: 123
    //   },
    //   mutations: {
    //     increment (state) {
    //       state.count++
    //     }
    //   },

  //state: {},
  // getters: {},
  //mutations: {},
  // actions: {},
  modules: {
    users,
    sidebar
  },
});