export default {
  setAuthError( state, payload ) {
    state.authError = payload;
  },

  setAuth( state, payload ) {
    state.auth = payload;
  },

  setRole( state, payload ) {
    state.role = payload;
  },
  setCompanyName( state, payload ) {
    state.companyName = payload;
  },
  setUserName( state, payload ) {
    state.username = payload;
  },
  setToken( state, payload ) {
    state.token = payload;
  }
}